import React from "react";
import { DotLoader as Component } from "react-spinners";

// Components
import { Backdrop } from "components/UI";

export const Spinner = ({ isLoading }) => {
  return (
    isLoading && (
      <Backdrop>
        <Component color="#ececec" />
      </Backdrop>
    )
  );
};
