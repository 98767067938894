import React, { forwardRef, useContext } from "react";
import { Snackbar as MUISnackbar, Alert as MUIAlert } from "@mui/material";

// Contexts
import { SnackbarContext } from "providers";

export const Snackbar = () => {
  const { state, closeSnackbar } = useContext(SnackbarContext);
  const { vertical, horizontal, open, severity, message, duration } = state;

  const Alert = forwardRef(function Alert(props, ref) {
    return <MUIAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <MUISnackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      message="I love snacks"
      key={vertical + horizontal}
      autoHideDuration={duration}
      onClose={closeSnackbar}
    >
      <Alert
        severity={severity}
        sx={{ width: "100%" }}
        style={{
          borderRadius: "50px",
          boxShadow: "0px 0px 0px",
          fontSize: "1.1em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "70vh"
        }}
      >
        {message}
      </Alert>
    </MUISnackbar>
  );
};
