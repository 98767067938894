import React, { useContext } from "react";

// Assets
import classes from "./Layout.module.css";

// Components
import {
  Snackbar,
  Sidebar,
  Hamburger,
  Navbar,
  Footer,
  BackToTopButton,
  Spinner
} from "components/UI";

// Contexts
import { SidebarContext, SpinnerContext, ThemeContext } from "providers";

export const Layout = ({ ...props }) => {
  const { children } = props;
  const { theme } = useContext(ThemeContext);
  const { state: sidebarState } = useContext(SidebarContext);
  const { isLoading } = useContext(SpinnerContext);

  const containerCss = `${classes.container} ${
    sidebarState && classes.activeContainer
  }`;

  const spinner = <Spinner isLoading={isLoading} />;

  const snackbar = <Snackbar />;

  const hamburger = <Hamburger color="#4698cf" />;

  const sidebar = <Sidebar />;

  const navbar = <Navbar />;

  const container = <div className={containerCss}>{children}</div>;

  const footer = <Footer />;

  const backToTopButton = (
    <BackToTopButton background={theme === "light" ? "#cccccc" : "#4d4d4d"} />
  );

  return (
    <div
      style={{ background: theme === "dark" && "#111111" }}
      className={classes.component}
    >
      {isLoading && spinner}
      {snackbar}
      {hamburger}
      {sidebar}
      {navbar}
      {container}
      {footer}
      {backToTopButton}
    </div>
  );
};
