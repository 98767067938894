import React, { useContext } from "react";

// Assets
import classes from "./BusinessConsultancy.module.css";
import img from "assets/images/businessConsultancy.jpg";
import secondImg from "assets/images/businessConsultancy2.jpg";

// Components
import { PageTitle, Typography } from "components/UI";

// Contexts
import { ThemeContext } from "providers";

export const BusinessConsultancy = () => {
  const { theme } = useContext(ThemeContext);

  const pageTitle = <PageTitle title="Consulenza Aziendale" />;

  const title = (
    <div
      style={{ backgroundColor: theme === "light" ? "#ececec" : "#282828" }}
      className={classes.title}
    >
      <Typography
        bold
        uppercase
        color={theme === "light" ? "#4d4d4d" : "#ffffff"}
        css={classes.text}
      >
        Consulenza e strategia bancaria
      </Typography>
    </div>
  );

  const imageCss = `${classes.img} ${theme === "dark" && classes.darkImg}`;
  const image = (
    <div className={classes.image}>
      <img src={img} alt="Fabrizio Pelliccione" className={imageCss} />
    </div>
  );

  const subtitle = (
    <Typography
      size={1.5}
      font="Montserrat"
      color={theme === "light" ? "#4d4d4d" : "#ececec"}
      css={classes.subtitle}
    >
      Strumenti e strategie per una migliore gestione del rapporto con le banche
      e gli istituti di credito
    </Typography>
  );

  const firstContainer = (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
          css={classes.title}
        >
          Il rapporto con le banche
        </Typography>
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.body}
        >
          <Typography bold>
            Saper dialogare con il sistema bancario è un plus valore che può
            favorire lo sviluppo di una società
          </Typography>
          <br />
          <br />
          L’evoluzione del mercato economico-finanziario rende spesso difficile,
          alle aziende, l’accesso al credito bancario. Pertanto diventa sempre
          più importante la capacità delle aziende di comunicare in modo chiaro
          e puntuale le informazioni quantitative e qualitative sulla loro
          attività per agevolare la banca nel processo di valutazione del merito
          creditizio.
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
          css={classes.title}
        >
          Perché rivolgersi ad un consulente?
        </Typography>
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.body}
        >
          <Typography bold>
            Saper dialogare con il sistema bancario è un plus valore che può
            favorire lo sviluppo di una società
          </Typography>
          <br />
          <br />
          Il consulente supporta l’azienda a migliorare la propria credibilità,
          ad individuare i propri fabbisogni e a presentarsi agli istituti di
          credito in modo appropriato con una documentazione ottimizzata ed in
          linea con le aspettative.
        </Typography>
      </div>
    </div>
  );

  const secondImageCss = `${classes.img2} ${
    theme === "dark" && classes.darkImg2
  }`;
  const secondImage = (
    <div className={classes.image}>
      <img
        src={secondImg}
        alt="Fabrizio Pelliccione"
        className={secondImageCss}
      />
    </div>
  );

  const hr = (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          border: `1px solid ${theme === "light" ? "#ececec" : "#4d4d4d"}`
        }}
        className={classes.hr}
      />
    </div>
  );

  const secondContainer = (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
          css={classes.title}
        >
          Analisi e gestione del rapporto con le banche
        </Typography>
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.body}
        >
          <Typography bold>
            Monitoriaggio e miglioramento del rating aziendale
          </Typography>
          <br />
          <br />
          Il rating rappresenta il giudizio applicato dalla Banca in merito alla
          affidabilità dell’azienda di ottenere linee di credito a breve termine
          (fidi di cassa, smobilizzo portafoglio/fatture, anticipi su contratti,
          garanzie italia ed estero, ecc.) e/o finanziamenti a medio/lungo
          termine (di tipo chirografario o assistiti da garanzie reali come
          l’ipoteca) Le strategie di miglioramento si basano sull’esame
          dell’andamentale cioè del rapporto operativo con la Banca,
          sull’analisi della Centrale dei Rischi e sull’analisi del Bilancio al
          fine di apportare dei correttivi utili al miglioramento del Rating.
          <br />
          <br />
          <Typography bold>
            Ottimizzazione delle condizioni bancarie applicate
          </Typography>
          <br />
          <br />
          Verrà fornito all’azienda richiedente un format da sottoporre alle
          Banche con cui lavora contenente le principali voci relative a tassi
          di interesse e spese applicati al fine di comparare in maniera
          efficace i servizi in essere ed ottenere risultati positivi nella
          trattativa delle condizioni al fine di ridurre gli oneri finanziari.
          <br />
          <br />
        </Typography>
      </div>
      <div className={classes.content}>
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
          css={classes.title}
        >
          Piano strategico-finanziario destinato all'ottenimento di
          finanziamenti
        </Typography>
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.body}
        >
          <Typography bold>
            Preparazione e presentazione presso le banche del piano
          </Typography>
          <br />
          <br />
          Per esaminare il merito creditizio, la Banca valuta i numeri e i
          documenti forniti innanzitutto dall’azienda stessa. Per questo motivo
          è importante una presentazione documentale alla Banca trasparente e
          ben dettagliata. Il nostro servizio prevede:
          <br />
          <br />
          <ul>
            <li>
              descrizione dettagliata dell’attività svolta (azienda ed eventuale
              gruppo di appartenenza). Modalità di redazione: tramite format
              predisposto con campi da completare ed incontro con il
              Titolare/Resp.le finanziario dell’azienda;
            </li>
            <li>
              andamento aziendale, prospettive di mercato, programmi di
              investimento e copertura finanziaria
            </li>
            <li>
              analisi del bilancio, dell’equilibrio economico-finanziario e del
              cash flow
            </li>
            <li>
              eventuale preparazione del business plan per esplicitare i dati
              del progetto necessari per ottenere il finanziamento.
            </li>
          </ul>
        </Typography>
      </div>
    </div>
  );

  const thirdContainer = (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
          css={classes.title}
        >
          Analisi della posizione in centrale dei rischi
        </Typography>
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.body}
        >
          <Typography bold>Controllo del profilo di rischio</Typography>
          <br />
          <br />
          La Centrale dei Rischi è un sistema informativo che monitora
          l’andamento che le persone fisiche e giuridiche hanno nei confronti
          delle Banche e Società finanziarie e che contribuisce a determinare e
          a mantenere aggiornato il profilo di rischio, rilevando come vengono
          utilizzati gli affidamenti concessi. Ogni fine mese le Banche
          comunicano a Banca d’Italia l’aggiornamento delle singole posizioni.
          Banca d’Italia elabora le informazioni e le restituisce al sistema
          creditizio e agli intermediari.
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      {pageTitle}
      <div className={classes.page} title="Fabrizio Pelliccione">
        {title}
        {image}
        {subtitle}
        {firstContainer}
        {secondImage}
        {hr}
        {secondContainer}
        {thirdContainer}
      </div>
    </>
  );
};
