import React, { createContext, useState } from "react";

// Utils
import { getTheme, setTheme as setApplicationTheme } from "utils";

export const ThemeContext = createContext(null);

const { Provider } = ThemeContext;

export const ThemeProvider = ({ ...props }) => {
  const { children } = props;

  const [theme, setTheme] = useState(getTheme() || "light");

  function themeHandler() {
    if (theme === "light") {
      setTheme("dark");
      setApplicationTheme("dark");
    } else if (theme === "dark") {
      setTheme("light");
      setApplicationTheme("light");
    }
  }

  return <Provider value={{ theme, themeHandler }}>{children}</Provider>;
};
