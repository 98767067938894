import React, { useState } from "react";

// Assets
import classes from "./BackToTopButton.module.css";

// Components
import { ArrowIcon } from "components/UI/icons";

export const BackToTopButton = ({ background = "#4d4d4d" }) => {
  const [state, setState] = useState(false);

  function checkScroll() {
    if (!state && window.pageYOffset > 20) setState(true);
    else if (state && window.pageYOffset <= 20) setState(false);
  }

  window.addEventListener("scroll", checkScroll);

  return (
    <div
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      style={{
        opacity: state ? ".8" : "0",
        zIndex: state ? "900" : "-900",
        backgroundColor: background
      }}
      className={classes.component}
    >
      <ArrowIcon style={{ color: "#ffffff" }} className={classes.icon} />
    </div>
  );
};
