import React, { createContext, useState } from "react";

export const SnackbarContext = createContext(null);

const { Provider } = SnackbarContext;

export const SnackbarProvider = ({ ...props }) => {
  const { children } = props;
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right"
  });

  function activeSnackbar(severity, message, duration = 5000) {
    setState((prevState) => {
      return { ...prevState, open: true, severity, message, duration };
    });
  }

  function closeSnackbar() {
    setState({ ...state, open: false });
  }

  return (
    <Provider
      value={{
        state,
        activeSnackbar,
        closeSnackbar
      }}
    >
      {children}
    </Provider>
  );
};
