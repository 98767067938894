import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

// Assets
import "./index.css";
import App from "./App";

// Providers
import {
  SidebarProvider,
  SnackbarProvider,
  SpinnerProvider,
  ThemeProvider
} from "providers";

const app = (
  <Router>
    <SpinnerProvider>
      <ThemeProvider>
        <SidebarProvider>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </SidebarProvider>
      </ThemeProvider>
    </SpinnerProvider>
  </Router>
);

ReactDOM.render(app, document.getElementById("root"));
