import React from "react";
import { Route, Routes as RRoutes } from "react-router-dom";

// Assets
import { routes } from "./routes";

export const Routes = () => {
  return (
    <RRoutes>
      {routes.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={route.component}
            title="Fabrizio Pelliccione"
          />
        );
      })}
    </RRoutes>
  );
};
