import React from "react";

// Assets
import classes from "./Backdrop.module.css";

export const Backdrop = ({ ...props }) => {
  const { children } = props;

  return <div className={classes.component}>{children}</div>;
};
