import React, { useContext, useState, useEffect } from "react";
import emailJs from "emailjs-com";

// Assets
import classes from "./Contacts.module.css";
import { websiteInformations } from "assets/constants";

// Components
import { PageTitle, Typography, Input, TextArea, Button } from "components/UI";

// Contexts
import { ThemeContext, SnackbarContext, SpinnerContext } from "providers";

// Helpers
import { validateEmail, validateRequired } from "helpers";

export const Contacts = () => {
  const [state, setState] = useState();
  const [validations, setValidations] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { activeSnackbar } = useContext(SnackbarContext);
  const { setIsLoading } = useContext(SpinnerContext);

  const pageTitle = <PageTitle title="Contatti" />;

  const title = (
    <div
      style={{ backgroundColor: theme === "light" ? "#ececec" : "#282828" }}
      className={classes.title}
    >
      <Typography
        bold
        uppercase
        color={theme === "light" ? "#4d4d4d" : "#ffffff"}
        css={classes.text}
      >
        Contatti
      </Typography>
    </div>
  );

  function inputHandler(propLabel, value) {
    setState((prevState) => {
      return { ...prevState, [propLabel]: value };
    });
  }

  function validateHandler(propLabel, value, validateFunction) {
    const res = validateFunction(value);
    setValidations((prevState) => {
      return {
        ...prevState,
        [propLabel]: {
          isValid: res?.isValid,
          errorMessage: res?.errorMessage
        }
      };
    });
  }

  function checkError(propLabel) {
    return validations?.[propLabel]?.isValid === false;
  }

  function cleanError(propLabel) {
    setValidations((prevState) => {
      return { ...prevState, [propLabel]: null };
    });
  }

  async function submitHandler(event) {
    setState((prevState) => {
      return { ...prevState, form: event.target };
    });
    event.preventDefault();
    await validateHandler("name", state?.name, validateRequired);
    await validateHandler("email", state?.email, validateEmail);
    await validateHandler("text", state?.text, validateRequired);
    setIsSubmitted(true);
  }

  async function onSubmit() {
    setIsLoading(true);
    emailJs
      .sendForm(
        "service_6fng6mk",
        "template_h6m4v8o",
        state?.form,
        "gKaBaembEzBBPs4qU"
      )
      .then((res) => {
        if (res?.status === 200) {
          activeSnackbar("success", "E-mail inviata con successo");
          setIsLoading(false);
          setState({
            name: "",
            email: "",
            text: ""
          });
          setValidations(null);
        } else
          activeSnackbar("error", "L'e-mail non è stata inviata correttamente");
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        activeSnackbar("error", "L'e-mail non è stata inviata correttamente");
      });
  }

  useEffect(() => {
    if (isSubmitted && validations)
      if (checkError("name") || checkError("email") || checkError("text"))
        setIsSubmitted(false);
      else onSubmit();
    // eslint-disable-next-line
  }, [isSubmitted, validations]);

  const form = (
    <div className={classes.form}>
      <form onSubmit={submitHandler} className={classes.content}>
        <Input
          name="name"
          placeholder="Nominativo"
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          background={theme === "light" ? "#ececec" : "#4d4d4d"}
          value={state?.name}
          onChange={(event) => {
            cleanError("name");
            inputHandler("name", event.target.value);
          }}
          error={checkError("name")}
          css={classes.input}
        />
        <Input
          name="message"
          placeholder="E-mail"
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          background={theme === "light" ? "#ececec" : "#4d4d4d"}
          value={state?.email}
          onChange={(event) => {
            cleanError("email");
            inputHandler("email", event.target.value);
          }}
          error={checkError("email")}
          css={classes.input}
        />
        {checkError("email") && validations?.email?.errorMessage && (
          <Typography color="#ff0000" size={1.2} css={classes.errorLabel}>
            {validations?.email?.errorMessage}
          </Typography>
        )}
        <TextArea
          name="message"
          placeholder="Testo"
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          background={theme === "light" ? "#ececec" : "#4d4d4d"}
          value={state?.text}
          onChange={(event) => {
            cleanError("text");
            inputHandler("text", event.target.value);
          }}
          error={checkError("text")}
          css={classes.textArea}
        />
        <Button
          color="#ffffff"
          background="#4698cf"
          type="submit"
          css={classes.btn}
        >
          <Typography size={1.5} bold uppercase>
            Invia
          </Typography>
        </Button>
      </form>
    </div>
  );

  const iframeCss = `${classes.iframe} ${
    theme === "dark" && classes.darkIframe
  }`;
  const map = (
    <div className={classes.map}>
      <iframe
        title="fabrizio pelliccione"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2799.484981201877!2d12.1377371!3d45.4398823!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477ec82ed99da239%3A0x9d897cf7139ab911!2sVia%20Mocenigo%2C%2038%2C%2030034%20Mira%20VE!5e0!3m2!1sit!2sit!4v1646358412700!5m2!1sit!2sit"
        className={iframeCss}
      />
    </div>
  );

  const details = (
    <div className={classes.details}>
      <Typography
        font="MontserratBold"
        size={1.3}
        color={theme === "light" ? "#4d4d4d" : "#ececec"}
      >
        Fabrizio Pelliccione
      </Typography>
      <br />
      <Typography color={theme === "light" ? "#4d4d4d" : "#cccccc"}>
        {websiteInformations.address}
        <br />
        Cell: {websiteInformations.phone}
        <br />
        Agente Credipass S.r.l.
        <br />
        Società di mediazione creditizia inscritta OAM N. M12
        <br />
        {websiteInformations.email}
        <br />
        P.IVA: {websiteInformations.vatNumber}
      </Typography>
      <br />
    </div>
  );

  const indications = (
    <div className={classes.indications}>
      {map}
      {details}
    </div>
  );

  return (
    <>
      {pageTitle}
      <div className={classes.page} title="Fabrizio Pelliccione">
        {title}
        {form}
        {indications}
      </div>
    </>
  );
};
