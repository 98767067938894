import React from "react";

export const Typography = ({
  font = "Verdana",
  size = null,
  spacing = null,
  color = null,
  bold = false,
  uppercase = false,
  css = null,
  ...props
}) => {
  const { children } = props;

  const componentCss = `${css}`;

  return (
    <span
      style={{
        fontFamily: font,
        fontSize: size && `${size}em`,
        color,
        textTransform: uppercase && "uppercase",
        fontWeight: bold && "bold",
        letterSpacing: spacing && `${spacing}vh`
      }}
      className={componentCss}
      {...props}
    >
      {children}
    </span>
  );
};
