import React, { useContext } from "react";

// Assets
import classes from "./Home.module.css";
import { websiteInformations } from "assets/constants";
import profileImg from "assets/images/profile.jpg";

// Components
import { PageTitle, Typography } from "components/UI";

// Contexts
import { ThemeContext } from "providers";

export const Home = () => {
  const { theme } = useContext(ThemeContext);

  const pageTitle = <PageTitle title="Home" />;

  const title = (
    <div
      style={{ backgroundColor: theme === "light" ? "#ececec" : "#282828" }}
      className={classes.title}
    >
      <Typography
        bold
        uppercase
        color={theme === "light" ? "#4d4d4d" : "#ffffff"}
        css={classes.text}
      >
        {websiteInformations.name}
      </Typography>
    </div>
  );

  const imgCss = `${classes.img} ${theme === "dark" && classes.darkImg}`;
  const profileImgComponent = (
    <div className={classes.profile}>
      <img src={profileImg} className={imgCss} alt="Fabrizio Pelliccione" />
    </div>
  );

  const description = (
    <div className={classes.description}>
      <div className={classes.content}>
        <Typography
          font="Montserrat"
          bold
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          css={classes.subtitle}
        >
          Fabrizio Pelliccione è un Personal Credit che vi offre consulenze
          personalizzate e assistenza bancaria, per il vostro mutuo casa,
          tutelandovi nelle complesse relazioni con le banche e le agenzie
          immobiliari.
        </Typography>
        <br />
        <br />
        <Typography
          font="Montserrat"
          color={theme === "light" ? "#767676" : "#cccccc"}
          css={classes.body}
        >
          Mi chiamo Fabrizio Pelliccione e sono nato nel ’79, nella splendida
          cornice di L’Aquila. Ultimo di tre fratelli, ho iniziato da subito a
          voler stare al passo con chi era più avanti di me… Così ho imparato a
          osservare gli atteggiamenti e le abitudini che rendevano in qualche
          modo straordinarie le persone: da bimbo erano i miei fratelli,
          crescendo chiunque fosse stato in grado di generare risultati
          eccezionali. Questa naturale predisposizione mi ha portato a studiare
          tenacemente e cercare sempre nuove sfide e nuovi modi di migliorare me
          stesso. Lo sport in prima battuta, è stato (ed è tutt’ora) uno dei
          miei più grandi maestri. Ho praticato per molti anni Rugby, il quale,
          oltre a togliermi qualche piccola soddisfazione, mi ha anche permesso
          di rimanere lontano dai tipici pericoli dell’adolescenza e di capire
          l’importanza di valori fondamentali come l’altruismo, la lealtà, la
          condivisione e l’apertura mentale. E’ stata per me una vera scuola di
          vita. In seguito mi sono appassionato anche al nuoto e allo yoga. Dal
          punto di vista lavorativo ho maturato esperienze decennali in rapporti
          commerciali-operativi ed ho in seguito scoperto il grande interesse
          per l’attività finanziaria. Ad oggi ricopro il ruolo di Personal
          Credit e Manager. Ho quindi trasformato una mia passione in un lavoro
          per aiutare più persone a comprare casa e accedere ad un mutuo. Non
          basta confrontare le offerte delle migliori banche o cercare casa su
          internet, è fondamentale contare su un servizio di consulenza
          personalizzato per pianificare al meglio la ricerca dell’immobile e
          relativo finanziamento. Amo aiutare le persone a raggiugere il miglior
          obiettivo con la massima trasparenza, professionalità, assistenza e
          fiducia. Costantemente analizzo il mercato interbancario e mi mantengo
          aggiornato studiando ogni giorno per offrire il miglior servizio
          possibile. Sarò felice di poter aiutare anche a te! Il Personal Credit
          vi tutelerà dalle complicatezze che si riscontrano nell’acquisto di
          una casa, vi farà ottenere il miglior mutuo per il vostro acquisto e
          sarà sempre al vostro fianco, dall’inizio e fino al rogito Notarile.
          Non effettuiamo alcuna vendita di prodotti finanziari e assicurativi.
          Prestiamo esclusivamente assistenza per un corretta pianificazione del
          credito finalizzato all’acquisto di un immobile che esso sia prima o
          seconda casa, ristrutturazione, liquidità, surroga, rifinanziamento o
          consolidamento debiti.
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      {pageTitle}
      <div className={classes.page} title="Fabrizio Pelliccione">
        {title}
        {profileImgComponent}
        {description}
      </div>
    </>
  );
};
