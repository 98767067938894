import React from "react";
import { Helmet } from "react-helmet";

// Assets
import { websiteInformations } from "assets/constants";

export const PageTitle = ({ title }) => {
  const _title = `${websiteInformations.name} - ${title}`;

  return (
    <Helmet>
      <title>{_title}</title>
    </Helmet>
  );
};
