import React, { useContext } from "react";

// Assets
import classes from "./Consultant.module.css";
import img from "assets/images/personalCredit.png";

// Components
import { PageTitle, Typography } from "components/UI";

// Contexts
import { ThemeContext } from "providers";

export const Consultant = () => {
  const { theme } = useContext(ThemeContext);

  const pageTitle = <PageTitle title="Consulente" />;

  const title = (
    <div
      style={{ backgroundColor: theme === "light" ? "#ececec" : "#282828" }}
      className={classes.title}
    >
      <Typography
        bold
        uppercase
        color={theme === "light" ? "#4d4d4d" : "#ffffff"}
        css={classes.text}
      >
        Cosa fa e come si comporta il consulente del credito
      </Typography>
    </div>
  );

  const imageCss = `${classes.img} ${theme === "dark" && classes.darkImg}`;
  const imgComponent = (
    <img src={img} alt="Fabrizio Pelliccione" className={imageCss} />
  );

  const description = (
    <div className={classes.description}>
      <Typography
        font="Montserrat"
        size={1.3}
        bold
        uppercase
        color={theme === "light" ? "#000000" : "#ffffff"}
        css={classes.title}
      >
        Il consulente del credito è un professionista delle scienze bancarie che
        conosce le leggi e le regole in materia creditizia.
      </Typography>
      <Typography
        color={theme === "light" ? "#4d4d4d" : "#ececec"}
        size={1.1}
        font="Montserrat"
        css={classes.body}
      >
        É costantemente formato e aggiornato sulle offerte commerciali dei mutui
        e le relative regole di accesso. Il consulente del credito è colui che
        mette in relazione le banche che devono vendere denaro e il consumatore
        che deve acquistarlo. Il consulente ha il compito di semplificare e
        ottimizzare il rapporto tra le parti{" "}
        <Typography bold>tutelando il consumatore</Typography> e appurando che
        la banca applichi realmente le condizioni contrattuali e commerciali
        opzionate al momento della scelta del mutuo e soprattutto che vengano
        rispettate le normative vigenti in materia creditizia.
        <br />
        <br />
        Il consulente deve conoscere le proposte commerciali delle banche ma
        anche l’ingegneria finanziaria che caratterizza l’offerta dei mutui,
        quindi consigliare con diligenza e coscienza{" "}
        <Typography bold>
          la soluzione commerciale o tecnica più idonea al consumatore
        </Typography>
        , a seconda dell’esigenza che si presenta. Il consulente sin dal primo
        incontro deve essere in grado di fare un’analisi reddituale reale
        dell’effettiva possibilità di indebitamento del consumatore, ma anche,
        in alcuni casi, dissuaderlo dal ricorso al credito qualora questo
        comporti un sovra-indebitamento.
      </Typography>
    </div>
  );

  const differences = (
    <div className={classes.differences}>
      <div className={classes.container}>
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
          css={classes.title}
        >
          Per il consumatore
        </Typography>
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.body}
        >
          Per il consumatore tutto questo si traduce, molto spesso, in un
          percorso ad ostacoli che il più delle volte porta ad un nulla di
          fatto. Oggi più che mai è necessaria un’educazione finanziaria
          preventiva prima di relazionarsi con una qualsiasi banca o prima di
          sottoscrivere un eventuale contratto di mutuo, anche se si tratta
          della propria banca di fiducia. Dovreste sempre fare analizzare la
          qualità dell’offerta di mutuo che vi viene sottoposta dalla vostra
          banca, affidandovi ad consulente del credito esterno alle banche. Solo
          così sarete certi che di fronte a voi ci sarà una persona che farà
          <Typography bold>esclusivamente il vostro interesse</Typography> in
          quanto non è stipendiato dalla banca che vi sottopone l’offerta.
          <br />
          <br />
          Come dimostrano i recenti fatti di cronaca finanziaria anche le banche
          che si reputavano essere le più affidabili non hanno messo al riparo i
          clienti e i risparmiatori dalle insidie del mercato speculativo e
          dalle truffe finanziarie. Molti istituti bancari hanno ingannato i
          loro clienti facendo leva proprio sulla storica fiducia acquisita
          negli anni di rapporto. Purtroppo però{" "}
          <Typography bold>
            quando vengono scoperte le irregolarità è ormai troppo tardi per
            correre ai ripari
          </Typography>{" "}
          e arginare le perdite per il consumatore.
        </Typography>
      </div>
      <div className={classes.container}>
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
          css={classes.title}
        >
          Professionista del credito
        </Typography>
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.body}
        >
          Il supporto di un professionista del credito si rivela un prezioso
          aiuto per mettervi al riparo dalle mille insidie “del fai da te”. Il
          consulente vi tutelerà e vi guiderà verso la scelta dell’istituto di
          credito più idoneo alle vostre esigenze scegliendo l’istituto più
          affidabile e -sopratutto-{" "}
          <Typography bold>che non sia in una fase di criticità</Typography>{" "}
          tale da coinvolgervi in una eventuale crisi.
          <br />
          <br />
          Il Consulente deve avere una sede legale presso la quale è abilitato a
          ricevere i propri clienti (diffidate da chi vi vuole incontrare il
          luoghi pubblici, accertatevi che abbia una sede per poterlo
          rintracciare).
          <br />
          <br />
          <Typography bold>Il consulente deve avere requisiti</Typography>{" "}
          formativi, reputazionali e di onorabilità ineccepibili, indispensabili
          per collaborare in modo professionale con gli istituti di credito e
          con il pubblico, come richiesto dalla normativa.
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      {pageTitle}
      <div className={classes.page} title="Fabrizio Pelliccione">
        {title}
        <div className={classes.container}>
          {imgComponent}
          {description}
        </div>
        {differences}
      </div>
    </>
  );
};
