import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Assets
import classes from "./Sidebar.module.css";
import { routes } from "components/routes/routes";

// Components
import { Typography } from "components/UI";

// Contexts
import { SidebarContext, ThemeContext } from "providers";

export const Sidebar = () => {
  const { state, stateHandler } = useContext(SidebarContext);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const componentCss = `${classes.component} ${
    state && classes.activeComponent
  }`;

  return (
    <div
      style={{ backgroundColor: theme === "dark" ? "#111111" : "#ffffff" }}
      className={componentCss}
    >
      {routes.map((route) => {
        return (
          <Typography
            key={route.path}
            font="MontserratBold"
            color={theme === "light" ? "#4d4d4d" : "#ececec"}
            size={1.4}
            bold
            uppercase
            onClick={() => {
              navigate(route.path);
              stateHandler();
            }}
            css={classes.route}
          >
            {route.name}
          </Typography>
        );
      })}
    </div>
  );
};
