import React, { useContext } from "react";

// Assets
import classes from "./Recipients.module.css";
import profileImg from "assets/images/recipients.jpg";
import homeImg from "assets/images/home.jpg";
import professionalsImg from "assets/images/professionals.jpg";
import familiesImg from "assets/images/families.jpg";
import estateAgencyImg from "assets/images/estateAgency.jpg";

// Components
import { PageTitle, Typography } from "components/UI";

// Contexts
import { ThemeContext } from "providers";

export const Recipients = () => {
  const { theme } = useContext(ThemeContext);

  const pageTitle = <PageTitle title="Destinatari" />;

  const title = (
    <div
      style={{ backgroundColor: theme === "light" ? "#ececec" : "#282828" }}
      className={classes.title}
    >
      <Typography
        bold
        uppercase
        color={theme === "light" ? "#4d4d4d" : "#ffffff"}
        css={classes.text}
      >
        A chi ci rivolgiamo
      </Typography>
    </div>
  );

  const profileImgCss = `${classes.img} ${theme === "dark" && classes.darkImg}`;
  const firstContainer = (
    <div className={classes.first}>
      <div className={classes.recipients}>
        <Typography
          color={theme === "light" ? "#363839" : "#ececec"}
          size={1.5}
          uppercase
          css={classes.text}
        >
          Ai privati
        </Typography>
        <Typography
          color={theme === "light" ? "#363839" : "#ececec"}
          size={1.5}
          uppercase
          css={classes.text}
        >
          Ai professionisti
        </Typography>
        <Typography
          color={theme === "light" ? "#363839" : "#ececec"}
          size={1.5}
          uppercase
          css={classes.text}
        >
          Alle aziende
        </Typography>
      </div>
      <div className={classes.image}>
        <img
          src={profileImg}
          alt="Fabrizio Pelliccione"
          className={profileImgCss}
        />
      </div>
    </div>
  );

  const imgCss = `${classes.img} ${theme === "dark" && classes.darkImg}`;
  const otherContainers = [
    {
      image: (
        <img src={homeImg} alt="Fabrizio Pelliccione" className={imgCss} />
      ),
      title: (
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
        >
          A tutti coloro che stanno progettando l’acquisto di una casa e
          vogliono sapere con esattezza di quanto possono disporre
          economicamente tra mutuo e proprio apporto personale.
        </Typography>
      ),
      content: (
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.content}
        >
          È fondamentale sapere in anticipo su che cifra si può contare tra
          mutuo (in rapporto ai propri redditi) ed eventuali risparmi
          accantonati affinché la ricerca dell’immobile sia calibrata sulle
          reali possibilità di spesa e di indebitamento personale.{" "}
          <Typography bold>
            A seguito del nostro incontro siamo in grado di rilasciare una
            certificazione reddituale che vale tanto quanto una predelibera
            bancaria.
          </Typography>{" "}
          La prequalifica reddituale, effettuata prima della ricerca
          immobiliare, vi pone in uno stato commerciale di maggior forza
          contrattuale nei confronti dei venditori o dell’agenzia immobiliare
          potendo offrire sin da subito certezze sulla cifra disponibile da
          destinare all’acquisto.
        </Typography>
      )
    },
    {
      image: (
        <img
          src={professionalsImg}
          alt="Fabrizio Pelliccione"
          className={imgCss}
        />
      ),
      title: (
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
        >
          A tutti i professionisti, manager, dirigenti, avvocati, medici,
          magistrati e imprenditori
        </Typography>
      ),
      content: (
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.content}
        >
          Tutti coloro che sono molto impegnati con la propria professione e non
          hanno il tempo di dedicarsi alla ricerca della banca giusta.
          Affidatevi ad un professionista che si occupi della comparazione
          bancaria e di tutti gli aspetti tecnici necessari per accedere ad un
          mutuo. Vi offriamo una verifica (anche a distanza via mail)
          dell’offerta di mutuo ricevuta dalla vostra banca ed effettuiamo una
          ricerca di mercato per appurare che ci siano condizioni economiche più
          vantaggiose rispetto a quella ricevuta dal proprio istituto di
          fiducia.
        </Typography>
      )
    },
    {
      image: (
        <img src={familiesImg} alt="Fabrizio Pelliccione" className={imgCss} />
      ),
      title: (
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
        >
          Alle famiglie
        </Typography>
      ),
      content: (
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.content}
        >
          <ul>
            <li>
              Alle famiglie che hanno già un mutuo e vorrebbero sostituirlo con
              condizioni economiche più vantaggiose rispetto a quelle
              sottoscritte a suo tempo. Verifichiamo il tasso applicato al
              vostro vecchio mutuo e lo compariamo con i migliori tassi presenti
              nel mercato. Una volta appurata la reale convenienza e
              quantificato il risparmio che genera il cambio mutuo si emigra
              verso la nuova banca tramite una nuova operazione che tecnicamente
              viene denominata <Typography bold>SURROGA</Typography>, questa
              tipologia di mutuo (per normativa) non comporta nessun costo
              bancario per il consumatore.
            </li>
            <li>
              A tutte le persone che hanno già un mutuo e nel frattempo hanno
              contratto altri finanziamenti e la somma delle rate inizia a
              pesare sul bilancio familiare{" "}
              <Typography bold>
                effettuiamo un rifinanziamento del vostro vecchio mutuo
                inserendo all’interno del nuovo mutuo tutti i finanziamenti
              </Typography>{" "}
              (consolidamento debiti) generando così un’unica rata più contenuta
              che{" "}
              <Typography bold>
                riporterà gli esborsi mensili alla normalità
              </Typography>
              .
            </li>
            <li>
              A tutte quelle persone che vorrebbero vendere il proprio immobile
              ma{" "}
              <Typography bold>
                il debito residuo del mutuo è più alto del valore commerciale
                dell’immobile
              </Typography>
              . Contattateci e ci occuperemo di trovare un accordo a stralcio
              con la vostra banca.
            </li>
          </ul>
        </Typography>
      )
    },
    {
      image: (
        <img
          src={estateAgencyImg}
          alt="Fabrizio Pelliccione"
          className={imgCss}
        />
      ),
      title: (
        <Typography
          font="Montserrat"
          size={1.3}
          bold
          uppercase
          color={theme === "light" ? "#000000" : "#ffffff"}
        >
          Alle agenzie immobiliari
        </Typography>
      ),
      content: (
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.content}
        >
          A tutte le agenzie immobiliari che oltre ad avere il difficile compito
          di mettere in relazione chi compra e chi vende si trovano sempre più
          spesso in difficoltà di fronte al potenziale acquirente che deve
          accedere al credito immobiliare,{" "}
          <Typography bold>causa di molte trattative sfumate</Typography>. Il
          nuovo decreto non consente più agli agenti immobiliari, anche in via
          del tutto strumentale, di suggerire offerte di mutuo o suggerire
          eventuali banche al proprio cliente. Proprio alla luce di questa nuova
          normativa
          <Typography bold>
            è indispensabile che l’agente immobiliare si avvalga del prezioso
            supporto di un professionista di fiducia
          </Typography>
          , al quale poter affidare la prequalifica reddituale del proprio
          cliente per arrivare in tempi certi e brevi verso il finanziamento
          immobiliare necessario all’acquisto.
        </Typography>
      )
    }
  ];

  const OtherContainer = ({ data }) => {
    const { image, title, content } = data;

    return (
      <div className={classes.container}>
        <div className={classes.image}>{image}</div>
        <div className={classes.description}>
          {title}
          {content}
        </div>
      </div>
    );
  };

  return (
    <>
      {pageTitle}
      <div className={classes.page} title="Fabrizio Pelliccione">
        {title}
        <div className={classes.container}>
          {firstContainer}
          <div className={classes.others}>
            {otherContainers.map((container) => (
              <OtherContainer data={container} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
