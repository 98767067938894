import React from "react";

// Assets
import classes from "./Button.module.css";

// Components
import { Typography } from "components/UI";

export const Button = ({ color, background, type, disabled, css = null }) => {
  const componentCss = `${classes.component} ${css}`;

  return (
    <button
      type={type}
      disabled={disabled}
      style={{ color, backgroundColor: background }}
      className={componentCss}
    >
      <Typography size={1.5} bold uppercase>
        Invia
      </Typography>
    </button>
  );
};
