import React, { useContext } from "react";

// Assets
import classes from "./Footer.module.css";
import { websiteInformations } from "assets/constants";

// Components
import { Typography } from "components/UI";

// Contexts
import { SidebarContext, ThemeContext } from "providers";

// Icons
import {
  EmailIcon,
  PhoneIcon,
  LocationIcon,
  LinkedinIcon,
  FacebookIcon,
  InstagramIcon,
  WhatsappIcon
} from "components/UI/icons";

export const Footer = () => {
  const { state: sidebarState } = useContext(SidebarContext);
  const { theme } = useContext(ThemeContext);

  const componentCss = `${classes.component} ${
    sidebarState && classes.activeComponent
  }`;

  const Column = ({ title, ...props }) => {
    const { children } = props;
    return (
      <div className={classes.column}>
        <div className={classes.title}>
          <Typography
            font="MontserratBold"
            size={1.1}
            uppercase
            color={theme === "dark" ? "#ececec" : "#4d4d4d"}
          >
            {title}
          </Typography>
        </div>
        <div className={classes.children}>{children}</div>
      </div>
    );
  };

  const Social = ({ link, icon }) => {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <div
          style={{
            color: theme === "dark" ? "#ececec" : "#4d4d4d",
            border: `2px solid ${theme === "dark" ? "#ececec" : "#4d4d4d"}`
          }}
          className={classes.social}
        >
          {icon}
        </div>
      </a>
    );
  };

  const columnRowCss = `${classes.columnRow} ${
    theme === "dark" && classes.darkColumnRow
  }`;

  const row1 = (
    <div className={classes.row}>
      <Column title={websiteInformations.name}>
        <Typography color={theme === "dark" ? "#ececec" : "#4d4d4d"} size={1.2}>
          P.IVA. {websiteInformations.vatNumber}
        </Typography>
      </Column>
      <Column title="Recapiti">
        <div className={columnRowCss}>
          <a
            style={{ display: "flex", alignItems: "center" }}
            href={`mailto: ${websiteInformations.email}`}
            className={columnRowCss}
          >
            <EmailIcon className={classes.icon} />
            <Typography size={1.1}>{websiteInformations.email}</Typography>
          </a>
        </div>
        <a
          href={`tel: ${websiteInformations.phone}`}
          style={{ marginTop: "1vh", display: "flex", alignItems: "center" }}
          className={columnRowCss}
        >
          <PhoneIcon className={classes.icon} />
          <Typography size={1.1}>{websiteInformations.phone}</Typography>
        </a>
        <div style={{ marginTop: "1vh" }} className={columnRowCss}>
          <LocationIcon
            style={{ color: theme === "dark" ? "#ececec" : "#4d4d4d" }}
            className={classes.icon}
          />
          <Typography
            color={theme === "dark" ? "#ececec" : "#4d4d4d"}
            size={1.1}
          >
            {websiteInformations.address}
          </Typography>
        </div>
      </Column>
      <Column title="Social">
        <div className={classes.socials}>
          <Social
            link={websiteInformations.social.linkedin}
            icon={<LinkedinIcon className={classes.icon} />}
          />
          <Social
            link={websiteInformations.social.facebook}
            icon={<FacebookIcon className={classes.icon} />}
          />
          <Social
            link={websiteInformations.social.instagram}
            icon={<InstagramIcon className={classes.icon} />}
          />
          <Social
            link={websiteInformations.social.whatsApp}
            icon={<WhatsappIcon className={classes.icon} />}
          />
        </div>
      </Column>
    </div>
  );

  const copyright = `@ ${websiteInformations.year} - ${websiteInformations.name}, made by ${websiteInformations.author} - All rights reserved`;

  const row2 = (
    <div
      style={{ backgroundColor: theme === "dark" ? "#ececec" : "#353535" }}
      className={classes.row}
    >
      <Typography color={theme === "dark" ? "#363839" : "#ececec"}>
        {copyright}
      </Typography>
    </div>
  );

  return (
    <div
      style={{ backgroundColor: theme === "dark" ? "#363839" : "#ececec" }}
      className={componentCss}
    >
      {row1}
      {row2}
    </div>
  );
};
