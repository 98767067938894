import React, { useContext } from "react";

// Assets
import classes from "./Hamburger.module.css";

// Contexts
import { SidebarContext, ThemeContext } from "providers";

export const Hamburger = ({ color = "#4d4d4d" }) => {
  const { state: sidebarState, stateHandler: sidebarHandler } =
    useContext(SidebarContext);
  const { theme } = useContext(ThemeContext);

  const lineCss = `${classes.line} ${sidebarState && classes.activeLine}`;
  const lines = [1, 2, 3].map((line) => (
    <div
      key={line}
      style={{
        backgroundColor: sidebarState
          ? color
          : theme === "light"
          ? "#4d4d4d"
          : "#ececec"
      }}
      className={lineCss}
    />
  ));

  const componentCss = `${classes.component} ${
    sidebarState && classes.componentActive
  }`;

  return (
    <div onClick={sidebarHandler} className={componentCss}>
      {lines}
    </div>
  );
};
