import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Components
import { Layout } from "components/UI";
import { Routes } from "components";

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Layout>
      <Routes />
    </Layout>
  );
};

export default App;
