// Pages
import {
  Home,
  Consultant,
  Contacts,
  BusinessConsultancy,
  Recipients,
  OperativeMode
} from "pages";

export const routes = [
  { path: "/", name: "Home", component: <Home /> },
  {
    path: "/consultant",
    name: "Consulente",
    component: <Consultant />
  },
  { path: "/recipients", name: "Destinatari", component: <Recipients /> },
  {
    path: "/businessconsultancy",
    name: "Consulenza Aziendale",
    component: <BusinessConsultancy />
  },
  {
    path: "/operativemode",
    name: "Modalità Operativa",
    component: <OperativeMode />
  },
  { path: "/contacts", name: "Contatti", component: <Contacts /> }
];
