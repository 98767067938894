import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Assets
import classes from "./Navbar.module.css";
import { routes } from "components/routes/routes";
import logoImg from "assets/images/logo.png";

// Components
import { Typography, Switch } from "components/UI";

// Contexts
import { SidebarContext, ThemeContext } from "providers";

// Icons
import { MoonIcon } from "components/UI/icons";

export const Navbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { theme, themeHandler } = useContext(ThemeContext);
  const { state: sidebarState } = useContext(SidebarContext);

  const componentCss = `${classes.component} ${
    sidebarState && classes.activeComponent
  }`;

  const logo = (
    <div className={classes.logo}>
      <img
        src={logoImg}
        alt="Fabrizio Pelliccione"
        onClick={() => navigate("/")}
        className={classes.img}
      />
    </div>
  );

  const routesComponent = (
    <div className={classes.routes}>
      {routes.map((route) => {
        const routeCss =
          pathname === route.path
            ? classes.activeRoute
            : theme === "light"
            ? classes.route
            : classes.darkModeRoute;

        return (
          route.path !== "/" && (
            <Typography
              key={route.path}
              size={1}
              bold
              uppercase
              onClick={() =>
                pathname !== route.path ? navigate(route.path) : undefined
              }
              css={routeCss}
            >
              {route.name}
            </Typography>
          )
        );
      })}
    </div>
  );

  const darkMode = (
    <div className={classes.nightMode}>
      <MoonIcon
        style={{ color: theme === "light" ? "#C3C3C3" : "#4698cf" }}
        className={classes.icon}
      />
      <Switch
        checked={theme === "dark"}
        onChange={() => themeHandler()}
        color="#4698cf"
      />
    </div>
  );

  return (
    <div className={componentCss}>
      {logo}
      {routesComponent}
      {darkMode}
    </div>
  );
};
