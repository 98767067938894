import React, { createContext, useState } from "react";

export const SpinnerContext = createContext(null);

const { Provider } = SpinnerContext;

export const SpinnerProvider = ({ ...props }) => {
  const { children } = props;

  const [isLoading, setIsLoading] = useState(false);

  return <Provider value={{ isLoading, setIsLoading }}>{children}</Provider>;
};
