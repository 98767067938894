import React, { useContext } from "react";

// Assets
import classes from "./OperativeMode.module.css";
import img from "assets/images/operativeMode.jpg";

// Components
import { PageTitle, Typography } from "components/UI";

// Contexts
import { ThemeContext } from "providers";

export const OperativeMode = () => {
  const { theme } = useContext(ThemeContext);

  const pageTitle = <PageTitle title="Modalità Operativa" />;

  const title = (
    <div
      style={{ backgroundColor: theme === "light" ? "#ececec" : "#282828" }}
      className={classes.title}
    >
      <Typography
        bold
        uppercase
        color={theme === "light" ? "#4d4d4d" : "#ffffff"}
        css={classes.text}
      >
        Modalità operativa
      </Typography>
    </div>
  );

  const subtitle = (
    <div className={classes.title}>
      <Typography
        uppercase
        color={theme === "light" ? "#4d4d4d" : "#ffffff"}
        css={classes.text}
      >
        Realizzare i vostri sogni è la nostra missione
      </Typography>
    </div>
  );

  const imgCss = `${classes.img} ${theme === "dark" && classes.darkImg}`;
  const firstContainer = (
    <div className={classes.first}>
      <div className={classes.image}>
        <img src={img} alt="Fabrizio Pelliccione" className={imgCss} />
      </div>
      <div className={classes.description}>
        <Typography
          color={theme === "light" ? "#4d4d4d" : "#ececec"}
          size={1.1}
          font="Montserrat"
          css={classes.text}
        >
          Fissando un appuntamento con noi potrete esporre le vostre esigenze e
          le vostre personali idee sul credito immobiliare. Il nostro compito
          sarà quello di far coincidere il più possibile le vostre esigenze con
          le regole del credito,{" "}
          <Typography bold>
            anche quando i vostri parametri non sono perfettamente in linea con
            gli scoring richiesti
          </Typography>{" "}
          dalle banche offrendovi comunque la possibilità di accedere al vostro
          mutuo con i migliori tassi di mercato.
          <br />
          <br />
          Individuata la soluzione finanziaria che più si addice alle vostre
          esigenze, vi consiglieremo l’iter istruttorio da seguire con la banca
          facendoci carico di tutte le incombenze burocratiche che sono da
          espletare prima di andare in banca, evitandovi noiose perdite di tempo
          ma soprattutto{" "}
          <Typography bold>
            salvaguardando i vostri interessi fino al giorno della stipula
            notarile
          </Typography>
          . Il nostro supporto vi garantisce tempi certi ed un percorso preciso
          e controllato della vostra richiesta di finanziamento. Il protocollo
          di lavoro ci consente di sapere in qualsiasi momento e con esattezza
          in che stato di avanzamento si trova la vostra richiesta di mutuo e
          quando verrà deliberata.
        </Typography>
      </div>
    </div>
  );

  const secondContainer = (
    <div className={classes.second}>
      <Typography
        font="Montserrat"
        size={1.3}
        bold
        uppercase
        color={theme === "light" ? "#000000" : "#ffffff"}
      >
        Precisiamo che non svolgiamo alcuna attività di vendita di prodotti
        finanziari e non abbiamo nessun rapporto subalternità con l’istituto di
        credito chi vi concederà il finanziamento immobiliare.
      </Typography>
      <br />
      <br />
      <Typography
        color={theme === "light" ? "#4d4d4d" : "#ececec"}
        font="Montserrat"
        css={classes.text}
      >
        Il suggerimento della banca è dettato esclusivamente dalla reale
        convenienza che offre un istituto di credito rispetto ad un altro.
        <br />
        <br />
        La scelta è suggerita anche dalla specificità dell’operazione che meglio
        si addice ad una banca piuttosto che ad un’altra.
        <br />
        <br />
        <Typography bold>
          La nostra indipendenza ci permette di consigliarvi con la massima
          obiettività, diligenza e cuore il miglior percorso da intraprendere.
        </Typography>
      </Typography>
    </div>
  );

  const containers = (
    <div className={classes.containers}>
      {firstContainer}
      {secondContainer}
    </div>
  );

  return (
    <>
      {pageTitle}
      <div className={classes.page} title="Fabrizio Pelliccione">
        {title}
        {subtitle}
        {containers}
      </div>
    </>
  );
};
