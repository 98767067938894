import React, { useContext } from "react";

// Assets
import classes from "./TextArea.module.css";

// Contexts
import { ThemeContext } from "providers";

export const TextArea = ({
  color,
  background,
  placeholder,
  value,
  onChange,
  onBlur,
  error = false,
  css = null,
  ...props
}) => {
  const { theme } = useContext(ThemeContext);

  const componentCss = `${classes.component} ${
    error && theme === "dark" ? classes.darkError : error && classes.error
  } ${css}`;

  return (
    <textarea
      style={{
        color,
        backgroundColor: background
      }}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={componentCss}
      {...props}
    />
  );
};
