import React, { createContext, useState } from "react";

export const SidebarContext = createContext(null);

const { Provider } = SidebarContext;

export const SidebarProvider = ({ ...props }) => {
  const { children } = props;

  const [state, setState] = useState(false);

  function stateHandler() {
    setState(!state);
  }

  return <Provider value={{ state, stateHandler }}>{children}</Provider>;
};
