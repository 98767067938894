export const websiteInformations = {
  name: "Fabrizio Pelliccione",
  vatNumber: "04427260270",
  email: "fabriziopelliccione@fppersonalcredit.it",
  phone: 3389968089,
  address: "Via Mocenigo n. 38, Mira",
  social: {
    linkedin: "https://www.linkedin.com/in/fabrizio-pelliccione-43a05712a/",
    facebook: "https://m.facebook.com/?_rdr",
    instagram: "https://www.instagram.com/fabriziopelliccione_credipass/",
    whatsApp: "https://api.whatsapp.com/send?phone=+393389968089"
  },
  year: 2022,
  author: "Kevin De Marchi"
};
